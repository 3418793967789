const $ = window.$ = require('jquery')
window.selectize = require('selectize')
const Cookie = require('js-cookie')

require('bootstrap')

require('promise-polyfill/src/polyfill')
require('./smooth-scroll')

$('select').selectize({
  plugins: ['remove_button']
})

function csrfSafeMethod(method) {
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
$.ajaxSetup({
  beforeSend: function(xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader("X-CSRFToken", Cookie.get('csrftoken'))
    }
  }
})

function param(name) {
  const param = location.search.split(name)[1]

  if (param) {
    return param.split('&')[0];
  }

  return param
}

import Vue from 'vue'
import VeeValidate from 'vee-validate'

import ApplicationForm from './components/application-form.vue'
import Newsletter from './components/newsletter.vue'
import Map from '../ts/components/map'

const days = {
  'MON': 'Pondělí',
  'TUE': 'Úterý',
  'WED': 'Středa',
  'THU': 'Čtvrtek',
  'FRI': 'Pátek',
  'SAT': 'Sobota',
  'SUN': 'Neděle'
}

Vue.filter('day', (value) => {
  if (value) {
    return days[value]
  }
})

Vue.filter('trim', (value) => {
  if (value) {
    return value.substr(0, value.length - 3)
  }
})

Vue.filter('date', (value) => {
  if (value) {
    return new Date(value).toLocaleDateString('cs-CZ', { year: 'numeric', day: 'numeric', month: 'short' })
  }
})

const isLeapYear = (year) => (year & 3) === 0 && ((year % 25) !== 0 || (year & 15) === 0)

VeeValidate.Validator.extend('personal_number', {
  getMessage: field => `This is not a ${field}.`,
  validate: (pid) => {
    if (pid.length < 9 || pid.length > 10) return false

    let year = parseInt(pid.slice(0, 2))
    let month = parseInt(pid.slice(2, 4))
    let day = parseInt(pid.slice(4, 6))

    if (pid.length === 9 && year > 54) return false
    year += (pid.length === 9 || (pid.length === 10 && year >= 54)) ? 1900 : 2000

    if (month > 70 && year > 2003) month -= 70
    if (month > 50) month -= 50
    if (month > 20 && year > 2003) month -= 20
    if (month < 1 || month > 12) return false

    if (day > 50) day -= 50
    if (day < 1 || day > 31) return false
    if ((month === 4 || month === 6 || month === 9 || month === 11) && (day > 30)) return false
    if ((month === 2) && isLeapYear(year) && (day > 29)) return false
    if ((month === 2) && !isLeapYear(year) && (day > 28)) return false

    if (pid.length > 9) {
      const pidBase = parseInt(pid.slice(0, 9))
      const control = parseInt(pid.slice(9))

      const mod = pidBase % 11

      if (mod !== control) {
        return (control === 0) && (mod === 10)
      }
    }

    return true
  }
})

const validationConfig = {
  classes: true,
  events: 'input|blur'
}

Vue.use(VeeValidate, validationConfig)

const element = $('#application-form-container')
if (element.length) {
  const serialized = element.data('serialized')

  if (param('ao') !== undefined) {
    serialized.course.available = 'AVAILABLE'
  }

  const props = {
    timings: serialized.timings,
    pricing: serialized.pricing,
    course: serialized.course
  }

  console.log(props)

  const applicationForm = new Vue({
    el: '#application-form-container',
    render: h => h(ApplicationForm, { props })
  })
}

const mapLocationElement = $('#location-map')
if (mapLocationElement.length) {
  const props = {
    locations: mapLocationElement.data('serialized'),
    mapURL: mapLocationElement.data('mapurl')
  }

  const mapLocation = new Vue({
    el: '#location-map',
    render: h => h(Map, { props })
  })
}

const newsletterForm = new Vue({
  el: '#newsletter',
  render: h => h(Newsletter)
})
