var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "newsletter" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "footer-newsletter row" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          !_vm.success
            ? _c(
                "form",
                {
                  staticClass: "form-inline",
                  attrs: { id: "newsletter-subscribe", action: "POST" },
                  on: { submit: _vm.validateOnSubmit }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "newsletter-input-container d-flex justify-content-start"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email",
                            expression: "'required|email'"
                          }
                        ],
                        staticClass: "newsletter-input form-control",
                        attrs: {
                          id: "email",
                          name: "email",
                          type: "text",
                          placeholder: "Vaše e-mailová adresa"
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("button", { staticClass: "newsletter-subscribe" }),
                      _vm._v(" "),
                      _vm.errors.has("email")
                        ? _c("div", { staticClass: "invalid-tooltip" }, [
                            _vm._v("Emailová adresa je povinná")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(1)
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.success
            ? _c("div", { staticClass: "newsletter-success" }, [
                _vm._v(
                  "\n            Děkujeme za přihlášení k odběru novinek.\n        "
                )
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6 newsletter-title" }, [
      _c("span", [_vm._v("Chci vědět o novinkách")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-start" }, [
      _c("span", { staticClass: "label-note" }, [
        _vm._v("Přihlášením odběru souhlasím se "),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: "https://horoguru.cz/obchodni-podminky"
            }
          },
          [_vm._v("zpracováním os. údajů dle zákona.")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }