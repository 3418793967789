<template>
<div class="newsletter">
  <div class="container">
    <div class="footer-newsletter row">
      <div class="col-md-6 newsletter-title">
        <span>Chci vědět o novinkách</span>
      </div>
      <div class="col-md-6">
        <form v-if="!success" id="newsletter-subscribe" class="form-inline" action="POST" @submit="validateOnSubmit">
          <div class="newsletter-input-container d-flex justify-content-start">
            <input v-model="email" id="email" name="email" type="text" class="newsletter-input form-control" placeholder="Vaše e-mailová adresa"  v-validate="'required|email'">
            <button class="newsletter-subscribe"></button>
            <div v-if="errors.has('email')" class="invalid-tooltip">Emailová adresa je povinná</div>
          </div>
          <div class="d-flex justify-content-start">
            <span class="label-note">Přihlášením odběru souhlasím se <a target="_blank" href='https://horoguru.cz/obchodni-podminky'>zpracováním os. údajů dle zákona.</a></span>
          </div>
        </form>

        <div v-if="success" class="newsletter-success">
            Děkujeme za přihlášení k odběru novinek.
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'newsletter',
  data: () => {
    return {
      email: '',
      success: false
    }
  },
  methods: {
    validateOnSubmit(event) {
      event.preventDefault()

      const vm = this
      this.$validator.validateAll()
        .then(function (result) {
          if (!result) return true

          $.ajax({
            type: 'POST',
            url: '/subscribe',
            processData: false,
            contentType: false,
            data: new FormData(event.target),
            success: function(response) {
              switch(response.status) {
                case 'AlreadySubscribed':
                  vm.success = false
                  return
                case 'Subscribed':
                  vm.success = true
                  return
                default:
                  vm.success = false
                  return
              }
            }
          })

          return false
        });
    }
  }
}
</script>

