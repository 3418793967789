var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        { active: _vm.active },
        "location-item",
        "d-flex",
        "align-items-center"
      ],
      style: _vm.positionStyle,
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.location.title))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }