






























import { Vue, Component, Prop } from 'vue-property-decorator'

import { MapLocation, MapPosition } from './map-location-type'
import MapLocationComponent from './map-location-component.vue'


Vue.component('map-location', MapLocationComponent)

type MapCorners = {
  left: number
  top: number
  right: number
  bottom: number
}

@Component
export default class Map extends Vue {
  @Prop() locations!: Array<MapLocation>
  @Prop() mapURL!: string

  corners: MapCorners = {
    left: 14.2986336,
    top: 50.1534436,
    right: 14.5695153,
    bottom: 50.0094361
  }

  currentLocation: MapLocation

  constructor() {
    super()

    this.currentLocation = this.randomLocation
  }

  get randomLocation(): MapLocation {
    const cibulka = this.locations.find((value) => (value.title === 'Cibulka'))

    return cibulka ? cibulka : this.locations[0]
  }

  showLocation(location: MapLocation): void {
    this.currentLocation = location
  }

  interpolateLocation(location: MapLocation): MapPosition {
    return {
      left: (location.left - this.corners.left) / (this.corners.right - this.corners.left) * 100,
      top: (location.top - this.corners.top) / (this.corners.bottom - this.corners.top) * 100
    }
  }
}
