var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "location-map col-12" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c(
          "div",
          { staticClass: "location-map-wrapper" },
          [
            _c("img", {
              staticClass: "location-map-image",
              attrs: { src: _vm.mapURL, alt: "Mapa kurzů" }
            }),
            _vm._v(" "),
            _vm._l(_vm.locations, function(location) {
              return _c("map-location", {
                key: location.id,
                attrs: {
                  location: location,
                  position: _vm.interpolateLocation(location),
                  active: _vm.currentLocation.id === location.id
                },
                on: {
                  click: function($event) {
                    return _vm.showLocation(location)
                  }
                }
              })
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-6 location-text" }, [
        _c("h3", { staticClass: "location-title" }, [
          _vm._v(_vm._s(_vm.currentLocation.title))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "location-description" }, [
          _vm._v(_vm._s(_vm.currentLocation.description))
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "location-list" }, [
          _c("li", { staticClass: "location-address" }, [
            _vm._v(_vm._s(_vm.currentLocation.address))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "section-more col-xs-12 d-flex justify-content-end" },
          [
            _c(
              "a",
              {
                staticClass: "show-more-link",
                attrs: { href: _vm.currentLocation.link }
              },
              [
                _c("span", { staticClass: "show-more-text" }, [
                  _vm._v("Seznam kurzů")
                ]),
                _vm._v(" "),
                _c("i", { staticClass: "show-more-arrow" })
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }