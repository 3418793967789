








import { Vue, Component, Prop } from 'vue-property-decorator'
import { MapLocation, MapPosition } from './map-location-type'


@Component
export default class MapLocationComponent extends Vue {
  @Prop() location!: MapLocation
  @Prop() position!: MapPosition
  @Prop() active!: boolean

  public positionStyle: { left: string, top: string }

  constructor() {
    super()

    this.positionStyle = {
      left: this.position.left + '%',
      top: this.position.top + '%'
    }
  }
}
