<template>
  <div id="application-form-wrapper" class="mx-auto col-lg-8">
    <form v-if="!finished" id="application-form" class="application-form" method="POST" @submit="validateOnSubmit">
      <fieldset class="margin-bottom">
        <div v-for="timing in sortedTimings" :key="timing.id" class="row timing-row">
          <div class="box box-info col-12">
            <div class="row">
              <div class="col-md">
                <span v-if="!course.is_event" class="box-title">{{ timing.day | day }}, {{ timing.start_time | trim }} – {{ timing.end_time | trim }}, {{ timing.location.title }} </span>
                <span v-if="course.is_event" class="box-title">{{ course.start_date | date }} – {{ course.end_date | date }}, {{ timing.location.title }} </span>
                <span class="box-subtitle box-multiline">
                  lektoři {{ timing.instructor.name }}, {{ timing.help_instructor.name }}<br />
                  Zbývá {{ timing.remaining_capacity }} míst z
                  {{ timing.capacity }}
                </span>
              </div>

              <div v-if="course.available === 'NOT_AVAILABLE'" class="col-md-auto box-right align-items-center d-flex">
                <span class="timing-occupied">Bude otevřeno</span>
              </div>
              <div v-else-if="timing.remaining_capacity > 0" class="col-md-auto box-right align-items-center d-flex">
                <a v-if="isValidSelection(timing.type) && !isTimingSelected(timing.id)" href="#" class="btn btn-primary" @click.prevent="selectTiming(timing.id)">Vybrat</a>
                <a v-if="isTimingSelected(timing.id)" href="#" class="btn btn-primary btn-selected" @click.prevent="deselectTiming(timing.id)">Zrušit</a>
              </div>
              <div v-else-if="timing.remaining_capacity <= 0" class="col-md-auto box-right align-items-center d-flex">
                <span class="timing-occupied">Obsazeno</span>
              </div>
              <div v-else-if="!isValidSelection(timing.type) && !isTimingSelected(timing.id)" class="col-md-auto box-right align-items-center d-flex">
                <span class="timing-occupied">Vyčerpané možnosti</span>
              </div>
            </div>
          </div>
        </div>

        <select class="d-none" v:model="selectedTimings" name="timing" id="timing" multiple v-validate="'required'">
          <option v-for="timing in timings" :value="timing.id" :selected="isTimingSelected(timing.id)" :key="timing.id">
            {{ timing.day }}
          </option>
        </select>

        <div v-if="errors.has('timing')">Vyberte alespoň jeden čas kurzu</div>
      </fieldset>

      <div v-if="selectedTimings.length > 0">
        <fieldset id="personal-informations">
          <div class="row">
            <div class="col">
              <h2>Údaje o lezci</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Křestní jméno <span class="label-required">*</span></label>
                <input name="name" type="text" class="form-control" v-validate="'required|alpha_spaces'" />
                <div v-if="errors.has('name')" class="invalid-tooltip">Křestní jméno je povinné</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="surname">Příjmení <span class="label-required">*</span></label>
                <input name="surname" type="text" class="form-control" v-validate="'required|alpha_spaces'" />
                <div v-if="errors.has('surname')" class="invalid-tooltip">Příjmení je povinné</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="personal_id">Rodné číslo <small class="text-muted">(bez lomítka)</small> <span class="label-required">*</span></label>
                <input name="personal_id" type="text" class="form-control" v-validate="'required|numeric|personal_number'" />
                <div v-if="errors.has('personal_id')" class="invalid-tooltip">Rodné číslo je povinné</div>
              </div>
            </div>
          </div>
          <div v-if="!onlyCibulkaSelected()" class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input v-model="chs" type="checkbox" name="chs" id="chs" class="custom-control-input" />
                  <label class="custom-control-label" for="chs">Již jsem členem Horoguru oddílu</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input v-model="shoe" type="checkbox" name="shoe" id="shoe" class="custom-control-input" />
                  <label class="custom-control-label" for="shoe">Chci půjčit lezečky</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="shoe">
            <div class="col-md-6">
              <div class="form-group">
                <label for="foot_size">Velikost nohy <span class="label-required">*</span></label>
                <select name="foot_size" class="form-control">
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                </select>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset v-if="course.child_course" id="child-course">
          <div class="row">
            <div class="col">
              <h2>Údaje o zákonném zástupci</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="parent_name">Křestní jméno zástupce <span class="label-required">*</span></label>
                <input type="text" name="parent_name" id="parent_name" class="form-control" v-validate="course.child_course ? 'required|alpha_spaces' : ''" />
                <div v-if="errors.has('parent_name')" class="invalid-tooltip">Křestní jméno zástupce je povinné</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="parent_surname">Příjmení zástupce <span class="label-required">*</span></label>
                <input type="text" name="parent_surname" id="parent_surname" class="form-control" v-validate="course.child_course ? 'required|alpha_spaces' : ''" />
                <div v-if="errors.has('parent_surname')" class="invalid-tooltip">Příjmení zástupce je povinné</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone">Telefon číslo na zástupce <span class="label-required">*</span></label>
                <input type="tel" name="phone" id="phone" class="form-control" v-validate="course.child_course ? 'required' : ''" />
                <div v-if="errors.has('phone')" class="invalid-tooltip">Telefon je povinný</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email na zástupce <span class="label-required">*</span></label>
                <input type="email" name="email" id="email" class="form-control" v-validate="course.child_course ? 'required|email' : ''" />
                <div v-if="errors.has('email')" class="invalid-tooltip">Email je povinný</div>
              </div>
            </div>
          </div>

          <fieldset v-if="!onlyCibulkaSelected()" id="chsInfo">
            <div class="row">
              <div class="col-md-9">
                <div class="form-group">
                  <label for="chs_street">Ulice <span class="label-required">*</span></label>
                  <input type="text" name="chs_street" id="chs_street" class="form-control" v-validate="'required'" />
                  <div v-if="errors.has('chs_street')" class="invalid-tooltip">Ulice je povinná</div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="chs_house_number">Číslo popisné <span class="label-required">*</span></label>
                  <input type="text" name="chs_house_number" id="chs_house_number" class="form-control" v-validate="'required'" />
                  <div v-if="errors.has('chs_house_number')" class="invalid-tooltip">Číslo popisné je povinné</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div class="form-group">
                  <label for="chs_city">Město <span class="label-required">*</span></label>
                  <input type="text" name="chs_city" id="chs_city" class="form-control" v-validate="'required'" />
                  <div v-if="errors.has('chs_city')" class="invalid-tooltip">Město je povinné</div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="chs_zip_code">PSČ <span class="label-required">*</span></label>
                  <input type="text" name="chs_zip_code" id="chs_zip_code" class="form-control" v-validate="'required'" />
                  <div v-if="errors.has('chs_zip_code')" class="invalid-tooltip">PSČ je povinné</div>
                </div>
              </div>
            </div>
          </fieldset>
        </fieldset>
        <fieldset v-else>
          <div class="row">
            <div class="col-12">
              <h2>Kontakt</h2>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone">Telefon číslo <span class="label-required">*</span></label>
                <input type="tel" name="phone" id="phone" class="form-control" v-validate="'required'" />
                <div v-if="errors.has('phone')" class="invalid-tooltip">Telefon je povinný</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email <span class="label-required">*</span></label>
                <input type="email" name="email" id="email" class="form-control" v-validate="!course.child_course ? 'required|email' : ''" />
                <div v-if="errors.has('email')" class="invalid-tooltip">Email je povinný</div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input v-model="invoice" type="checkbox" name="invoice" id="invoice" class="custom-control-input" />
                <label class="custom-control-label" for="invoice">Chci vystavit fakturu</label>
              </div>
            </div>
          </div>
        </div>

        <fieldset v-if="invoice" id="invoice">
          <div class="row">
            <div class="col">
              <h2>Fakturační údaje</h2>
              <p>Údaje slouží pouze jako podklad k vytvoření faktury, o vystavení nám prosíme napište na email <a href="mailto:info@horoguru.cz">info@horoguru.cz</a>. Děkujeme.</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="ico">IČO <span class="label-required">*</span></label>
                <input name="ico" type="text" class="form-control" v-validate="invoice ? 'required|numeric' : ''" />
                <div v-if="errors.has('ico')" class="invalid-tooltip">IČO je povinné</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="dic">DIČ</label>
                <input name="dic" type="text" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-9">
              <div class="form-group">
                <label for="street">Ulice <span class="label-required">*</span></label>
                <input type="text" name="street" id="street" class="form-control" v-validate="invoice ? 'required' : ''" />
                <div v-if="errors.has('street')" class="invalid-tooltip">Ulice je povinnná</div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="house_number">Číslo popisné <span class="label-required">*</span></label>
                <input type="text" name="house_number" id="house_number" class="form-control" v-validate="invoice ? 'required' : ''" />
                <div v-if="errors.has('house_number')" class="invalid-tooltip">Čislo popisné je povinné</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9">
              <div class="form-group">
                <label for="city">Město <span class="label-required">*</span></label>
                <input type="text" name="city" id="city" class="form-control" v-validate="invoice ? 'required' : ''" />
                <div v-if="errors.has('city')" class="invalid-tooltip">Město je povinné</div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="zip_code">PSČ <span class="label-required">*</span></label>
                <input type="text" name="zip_code" id="zip_code" class="form-control" v-validate="invoice ? 'required' : ''" />
                <div v-if="errors.has('zip_code')" class="invalid-tooltip">PSČ je povinné</div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="row sign-in-container">
          <div class="col">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="custom-control custom-checkbox terms-of-service">
                    <input type="checkbox" name="newsletter" id="newsletter-check" class="custom-control-input" checked />
                    <label class="custom-control-label" for="newsletter-check">Chci dostávat novinky o Horoguru</label>
                  </div>
                  <div class="custom-control custom-checkbox terms-of-service">
                    <input type="checkbox" name="tos" id="tos" class="custom-control-input" v-validate="'required'" />
                    <label class="custom-control-label" for="tos">
                      Souhlasím s
                      <a href="https://horoguru.cz/obchodni-podminky" target="_blank">podmínkami Horoguru</a>
                      <span class="label-required">*</span>
                      <div v-if="errors.has('tos')" class="invalid-tooltip">Je třeba souhlasit s podmínkami Horoguru</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button :disabled="submitDisabled" type="submit" class="btn btn-primary">Přihlásit</button>
              </div>
              <div class="col final-price">
                <span class="price">{{ finalPrice }} Kč</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showDiscountNotice(course)" class="row">
          <div class="col">
            <p class="text-muted">
              * cena je finální, ale bez jakkýchkoliv slev. Pokud chcete uplatnit slevu na sourozence, napište email na
              <a href="mailto:info@horoguru.cz">info@horoguru.cz</a>.
            </p>
          </div>
        </div>
      </div>
    </form>

    <div v-else class="application-finished">
      <h3>Přihlášení proběhlo úspěšně!</h3>

      <p>Děkujeme za přihlášení na kurz. Všechny důležité a doplňující informace Vám zašleme automaticky na zadanou emailovou adresu (zkontrolujte SPAM, občas nám to tam padá).</p>

      <h4>Jste přihlášeni na tyto kurzy</h4>
      <template v-for="timing in timings">
        <div v-if="isTimingSelected(timing.id)" :key="timing.id" class="row">
          <div class="box box-info col-12">
            <div class="row">
              <div class="col-6">
                <span v-if="!course.is_event" class="box-title">{{ timing.day | day }}, {{ timing.start_time | trim }} – {{ timing.end_time | trim }}, {{ timing.location.title }}</span>
                <span v-if="course.is_event" class="box-title">{{ course.start_date | date }} – {{ course.end_date | date }} </span>
                <span class="box-subtitle box-multiline">
                  lektoři {{ timing.instructor.name }},
                  {{ timing.help_instructor.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <h4>Cena</h4>
      <p>
        Výsledná cena: <span class="price">{{ finalPrice }} Kč</span>
      </p>
      <p v-if="!course.is_event" class="text-muted">
        Výsledná cena je beze slev. Pokud chcete uplatnit slevu na sourozence, napište email na
        <a href="mailto:info@horoguru.cz">info@horoguru.cz</a> a kurz zaplaťte poté.
      </p>

      <h4 v-if="!course.is_event">Dobré vědět</h4>
      <ul v-if="!course.is_event">
        <li>
          Kroužky začínají v týdnu od
          <strong>{{ course.start_date | date }}</strong
          >. Během školních prázdnin a svátků kroužky nejsou. Pololetí končí <strong>{{ course.end_date | date }}</strong
          >.
        </li>
        <li>
          Pokud Vaše dítě navštěvuje náš horokroužek již delší dobu (rok a déle) a počítá s lezením i nadále,
          <strong>prosíme o zakoupení vlastních lezeček</strong>, a to především z hygienických důvodů.
        </li>
        <li><strong>Pro začátečníky</strong>, kteří si svou dlouhodobou účastí zatím nejsou jisti, samozřejmě lezečky i ostatní lezecký materiál k zapůjčení mít budeme.</li>
        <li>Na kroužek se chodí <strong>v pohodlném sportovním oblečení</strong>, doporučujeme mít s sebou i pití a v zimním období mikinu.</li>
      </ul>
    </div>
  </div>
</template>

<script>
const days = {
  MON: 0,
  TUE: 1,
  WED: 2,
  THU: 3,
  FRI: 4,
  SAT: 5,
  SUN: 6,
};

export default {
  name: "application-form",
  data: () => {
    return {
      finished: false,
      chs: false,
      invoice: false,
      shoe: false,
      selectedTimings: [],
      submitDisabled: false,
    };
  },
  props: ["timings", "pricing", "course"],
  computed: {
    sortedTimings: function () {
      return this.timings.sort((a, b) => {
        if (a.day === b.day) {
          return a.start_time.localeCompare(b.start_time);
        }

        return days[a.day] - days[b.day];
      });
    },
    processedTimings: function () {
      return this.timings.reduce((acc, timing) => {
        acc[timing.id] = timing;
        return acc;
      }, {});
    },
    processedPricing: function () {
      return this.pricing.reduce((acc, timing) => {
        acc.set(timing.combination, timing.price);
        return acc;
      }, new Map());
    },
    finalPrice: function () {
      if (this.selectedTimings.length == 0) return 0;

      const serialized = this.serializeSelectedTimings();

      if (this.processedPricing.has(serialized.precise)) {
        return this.processedPricing.get(serialized.precise);
      }

      if (this.processedPricing.has(serialized.wild)) {
        return this.processedPricing.get(serialized.wild);
      }

      return 0;
    },
  },
  methods: {
    showDiscountNotice: function (course) {
      return course.child_course && !course.is_event;
    },
    isValidSelection: function (nextType) {
      const selected = this.selectedTimings.map((selected) => this.processedTimings[selected].type);
      selected.push(nextType);

      const serialized = this.serializeTimings(selected);

      return this.processedPricing.has(serialized.precise) || this.processedPricing.has(serialized.wild);
    },
    selectTiming: function (id) {
      this.selectedTimings.push(id);
    },
    deselectTiming: function (id) {
      if (this.isTimingSelected(id)) {
        this.selectedTimings = this.selectedTimings.filter((value) => value !== id);
      }
    },
    isTimingSelected: function (id) {
      return this.selectedTimings.indexOf(id) > -1;
    },
    onlyCibulkaSelected: function (id) {
      return this.selectedTimings.every((id) => this.processedTimings[id].location.title === "Cibulka");
    },
    validateTimings(event) {
      this.$validator.validate("timing");
    },
    validateOnSubmit(event) {
      event.preventDefault();

      var that = this;
      this.$validator.validateAll().then((result) => {
        if (!result) return true;

        that.submitDisabled = true;
        $.ajax({
          type: "POST",
          url: window.location.pathname,
          processData: false,
          contentType: false,
          data: new FormData(event.target),
          success: function (response) {
            that.finished = true;

            const scrollPos = $("#application-form-wrapper").position().top;
            $(window).scrollTop(scrollPos - 50);
          },
        });

        return false;
      });
    },
    serializeSelectedTimings: function () {
      const selected = this.selectedTimings.map((selected) => this.processedTimings[selected].type);

      return this.serializeTimings(selected);
    },
    serializeTimings: function (timings) {
      let wild = timings.reduce((acc, item, index) => {
        acc = `${acc}"*"`;
        acc = index === timings.length - 1 ? acc + "]" : acc + ",";

        return acc;
      }, "[");
      let precise = timings.sort().reduce((acc, item, index) => {
        acc = `${acc}"${item}"`;
        acc = index === timings.length - 1 ? acc + "]" : acc + ",";

        return acc;
      }, "[");

      return {
        wild,
        precise,
      };
    },
  },
};
</script>
